<template>
  <div>
    <headers />
    <div class="head">
      <img src="@/assets/images/solutionBj.png" />
      <div class="headTit">解决方案</div>
      <div class="searchBox">
        <search @search="searchFun" :type="2" />
      </div>
    </div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资料获取</el-breadcrumb-item>
        <el-breadcrumb-item>解决方案</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <template v-if="list.length">
    <div class="content">
      <div class="card">
        <div
          class="cardItem"
          v-for="(item, index) in list"
          :key="index"
          @click="toSolutionDetails(item)"
        >
          <div class="imgBox">
            <img
            class="hoverTransform"
              :src="item.image"
            />
          </div>

          <div class="title textOverflowOne">{{item.name}}</div>

          <div class="titleDetails textOverflowTwo">
            {{item.industry}}
          </div>

          <div class="btnBox">
            <div>浏览：{{item.readNum}}</div>
            <el-button class="searchBoxR">查看资料</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pages.total"
        :page-size="pages.size"
        :current-page="pages.page"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
   </template>
    <None v-else />
    <footers />
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";

import { solutionList } from "@/api/lx";

export default {
  name: "Procucts",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    return {
      actOneMeun: 0,
      actTwoMeun: 0,
      searchValue: "",
      pages: {
        page: 1,
        total: 0,
        size: 15,
      },
      list: [],
    };
  },
  created() {
    this.getSolutionList();
  },
  mounted() {},
  methods: {
    searchFun(val) {
      this.searchValue = val;
      this.pages.page = 1;
      this.getSolutionList();
    },
    currentChange(val) {
      this.pages.page = val;
      this.getSolutionList();
    },
    getSolutionList() {
      solutionList({
        page: this.pages.page,
        size: this.pages.size,
        name: this.searchValue,
      }).then((res) => {
        this.list = res.data.records;
        this.pages.total = res.data.total;
      });
    },
    actMenuOne(val) {
      if (this.actOneMeun == val.id) {
        this.actOneMeun = "";
      } else {
        this.actOneMeun = val.id;
      }
    },
    toSolutionDetails(item) {
      this.$router.push({
        path: "/information/solutionDetails",
        query: { id: item.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden; 
  .headTit {
    width: 100%;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 42px;
    color: #ffffff;
    position: relative;
    z-index: 1;
    margin-top: 80px;
  }
  .searchBox {
    margin-top: 54px;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .cardItem {
    width: 330px;
    margin-left: 45px;
    margin-bottom: 50px;
    .imgBox {
      width: 330px;
      height: 186px;
      border: 1px dashed #e6edf0;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-weight: 600;
      font-size: 18px;
      color: #00161a;
      margin-top: 15px;
    }
    .titleDetails {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      height: 40px;
      color: #525f66;
      text-align: left;
      margin-top: 10px;
    }
    .btnBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      div {
        font-weight: 400;
        font-size: 14px;
        color: #525f66;
      }
      .searchBoxR {
        width: 104px;
        height: 39px;
        background: #00d8c3;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
        &.is-active,
        &:active {
          border: 1px solid #00d8c3; /* 更改为你想要的颜色 */
          outline: none; /* 移除默认的轮廓线 */
        }
      }
    }
  }
}
.page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 65px;
}
</style>